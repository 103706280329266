<template>
  <div>
    <template v-if="withTitle">
      <h4
        class="panel__title"
        v-html="$sanitizeHtml(planLimitTitle)"/>
    </template>
    <v-progress-linear
      :class="['licence-progress-bar', severityClass, {
        'licence-progress-bar--sm': sm,
      }]"
      :value="value"/>
    <template v-if="withDescription">
      <v-icon
        v-if="showAlert"
        small
        :class="['licence-alert-icon', severityClass]">
        warning
      </v-icon>
      <span
        :class="['licence-alert-text', severityClass]"
        v-html="$sanitizeHtml(planTimeLeftText)"/>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { thresholds, plans } from '@/utils/config/billing'

export const METRIC = {
  TIME: 'time',
  MEMBERS: 'members',
}

export default {
  name: 'CyLicenceProgressBar',
  props: {
    memberType: {
      type: String,
      default: 'licence',
    },
    metric: {
      type: String,
      validator: (value) => _.values(METRIC).includes(value),
      default: METRIC.TIME,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    withDescription: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('organization', [
      'subscriptionMembersLeftPercentage',
    ]),
    ...mapGetters('organization/billing', [
      'daysRemaining',
      'hoursRemaining',
      'isEnterprise',
      'isFreeTrial',
      'subscriptionPlan',
      'trialExpired',
    ]),
    ...mapGetters('organization/licence', {
      licenceMembersLeftPercentage: 'membersLeftPercentage',
    }),
    membersLeftPercentage () {
      return this.memberType === 'licence' ? this.licenceMembersLeftPercentage : this.subscriptionMembersLeftPercentage
    },
    showAlert () {
      const { metric, daysRemaining, membersLeftPercentage } = this
      const { TIME, MEMBERS } = METRIC

      switch (metric) {
        case TIME:
          return daysRemaining <= thresholds.time.warning

        case MEMBERS:
          return membersLeftPercentage <= thresholds.members.warning
      }

      return false
    },
    severityClass () {
      const { metric, daysRemaining, membersLeftPercentage } = this
      const { TIME, MEMBERS } = METRIC

      switch (metric) {
        case TIME:
          if (daysRemaining <= thresholds.time.critical) return 'severity-high'
          if (daysRemaining <= thresholds.time.warning) return 'severity-mid'
          break

        case MEMBERS:
          if (membersLeftPercentage <= thresholds.members.critical) return 'severity-high'
          if (membersLeftPercentage <= thresholds.members.warning) return 'severity-mid'
          break
      }

      return 'severity-low'
    },
    planLimitTitle () {
      const { subscriptionPlan: { maxUsers }, trialExpired, isEnterprise, isFreeTrial } = this

      if (isEnterprise) return `${this.$t('maximumUsers')}: <span class='max-users'>${maxUsers}</span>`
      if (isFreeTrial || trialExpired) return this.$t('trialDuration')

      return ''
    },
    planTimeLeftText () {
      const { daysRemaining, subscriptionPlan: { usersLeft } = {}, hoursRemaining, trialExpired, isFreeTrial, isEnterprise } = this

      if (isEnterprise) return usersLeft > 0 ? `${usersLeft} ${this.$t('usersLeft')}` : this.$t('noUsersLeft')
      if (!isFreeTrial && !trialExpired) return ''
      if (trialExpired) return this.$t('trialExpired')
      if (daysRemaining > 0) return `${this.$tc('daysLeft', daysRemaining, { daysRemaining })}`
      if (hoursRemaining > 0) return `${this.$tc('hoursLeft', hoursRemaining, { hoursRemaining })}`

      return this.$t('lessThanOneHourLeft')
    },
    value () {
      const { trialExpired, daysRemaining, metric, membersLeftPercentage } = this
      if (daysRemaining >= plans.freeTrial.daysDefault) return 100
      if (metric === METRIC.TIME) return trialExpired ? 0 : Math.max(3.33, daysRemaining * 100 / 30)
      if (metric === METRIC.MEMBERS) return membersLeftPercentage
      return 0
    },
  },
  i18n: {
    messages: {
      en: {
        daysLeft: '{daysRemaining} day left | {daysRemaining} days left',
        hoursLeft: '{hoursRemaining} hour left | {hoursRemaining} hours left',
        lessThanOneHourLeft: 'Less than one hour left',
        maximumUsers: 'Maximum users',
        noLimitationOn: 'No limitation on the {planName} plan',
        noUsersLeft: 'No users left',
        trialDuration: 'Trial duration',
        trialExpired: 'Trial expired',
        usersLeft: 'users left',
      },
      es: {
        daysLeft: '{daysRemaining} día restante | {daysRemaining} días restantes',
        hoursLeft: '{hoursRemaining} hora restante | {hoursRemaining} horas restantes',
        lessThanOneHourLeft: 'Menos de una hora restante',
        maximumUsers: 'Usuarios máximos',
        noLimitationOn: 'Sin limitaciones para el plan {planName}',
        noUsersLeft: 'No quedan usuarios',
        trialDuration: 'Duración de la prueba',
        trialExpired: 'Prueba vencida',
        usersLeft: 'usuarios restantes',
      },
      fr: {
        daysLeft: '{daysRemaining} jour restant | {daysRemaining} jours restants',
        hoursLeft: '{hoursRemaining} heure restante | {hoursRemaining} heures restantes',
        lessThanOneHourLeft: `Il reste moins d'une heure`,
        maximumUsers: 'Utilisateurs maximum',
        noLimitationOn: 'Aucune limitation pour le plan {planName}',
        noUsersLeft: 'Aucun utilisateur restant',
        trialDuration: `Durée de l'essai`,
        trialExpired: 'Essai expiré',
        usersLeft: 'utilisateurs restants',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$licence-severity-high: cy-get-color("error", "light-1");
$licence-severity-mid: cy-get-color("warning", "light-1");
$licence-severity-low: cy-get-color("secondary", "light-1");

::v-deep .licence-progress-bar {
  height: 8px !important;
  margin: 4px auto;
  border-radius: 4px;

  &--sm {
    height: 4px !important;
  }

  .v-progress-linear__bar.primary,
  .v-progress-linear__background.primary {
    background-color: cy-get-color("grey") !important;
  }

  &.severity-high {
    .v-progress-linear__determinate.primary {
      border-color: $licence-severity-high !important;
      background-color: $licence-severity-high !important;
    }
  }

  &.severity-mid {
    .v-progress-linear__determinate.primary {
      border-color: $licence-severity-mid !important;
      background-color: $licence-severity-mid !important;
    }
  }

  &.severity-low {
    .v-progress-linear__determinate.primary {
      border-color: $licence-severity-low !important;
      background-color: $licence-severity-low !important;
    }
  }
}

.licence-alert-icon {
  &.severity-high {
    color: cy-get-color("error");
  }

  &.severity-mid {
    color: cy-get-color("warning");
  }

  .v-icon {
    margin-right: 2px;
    font-size: $font-size-lg;
  }
}

.licence-alert-text {
  margin-left: 2px;
}

</style>
